



















































































import { Component, Provide, Vue } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import { walletStore } from '@/stores/wallet-store'

@Observer
@Component({
  components: {},
})
export default class NavigationBar extends Vue {
  @Provide() walletStore = walletStore
  //TODO: Refactor register, login, myPage to component
  async login() {
    const res = await this.walletStore.signIn()
    if (res) this.walletStore.changeShowConnectDialog(false)
  }
}
